import * as React from 'react';
import {isError} from '@sentry/utils';
import {SENTRY} from '@youtoken/ui.sentry';
import {CustomError, ResourceAggregateError} from '@youtoken/ui.errors';
import {ErrorFallback, type ErrorFallbackProps} from './Fallbacks';

export interface ErrorBoundaryProps {
  FallbackComponent: React.FC<ErrorFallbackProps>;
  tags?: Record<string, any>;
  extra?: Record<string, any>;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  reportingResult?: {eventId: string};
  retriesCount: number;
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
    retriesCount: 0,
    reportingResult: undefined,
  };

  static defaultProps: ErrorBoundaryProps = {
    FallbackComponent: ErrorFallback,
    tags: {},
    extra: {},
  };

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const {extra, tags} = this.props;

    if (isError(error)) {
      error.name = `ErrorBoundary: ${error.name}`;

      if (
        error instanceof ResourceAggregateError &&
        error.errors &&
        error.errors.length > 0
      ) {
        error.errors.forEach(error => {
          SENTRY.captureException(error, {
            mechanism: {handled: true},
          });
        });
      }
    }

    const eventId = SENTRY.captureException(error, {
      captureContext: {
        contexts: {react: {componentStack: errorInfo.componentStack}},
        tags: tags,
        extra: {
          ...extra,
          componentStack: errorInfo.componentStack,
        },
      },

      // If users provide a fallback component we can assume they are handling the error.
      // Therefore, we set the mechanism depending on the presence of the fallback prop.
      mechanism: {handled: true},
    });

    this.setState(({retriesCount}) => ({
      reportingResult: {eventId},
      retriesCount: retriesCount + 1,
    }));
  }

  dismissError = () => {
    this.setState({
      hasError: false,
      error: undefined,
      reportingResult: undefined,
    });
  };

  render() {
    const {FallbackComponent, children} = this.props;
    const {hasError, error, reportingResult, retriesCount} = this.state;

    if (hasError) {
      return (
        <FallbackComponent
          error={error}
          dismissError={this.dismissError}
          reportingResult={reportingResult}
          retriesCount={retriesCount}
        />
      );
    }

    return <>{children}</>;
  }
}
